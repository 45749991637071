import React from "react"
import { graphql } from "gatsby"

import { imageUrlFor, buildImageObj, mapEdgesToNodes } from "../../utils"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import {
  CardWrapper,
  CardHeader,
  CardHeading,
  CardFieldset,
} from "../../components/Card"
import ExternalLink from "../../components/ExternalLink"
import BlockContent from "../../components/BlockContent"

const Projects = ({ data }) => {
  const headerSecondary = data.secondary.edges[0].node.childImageSharp.fluid
  const projectNodes = data && data.projects && mapEdgesToNodes(data.projects)
  console.log(projectNodes)
  return (
    <Layout img={headerSecondary}>
      <SEO
        title="Projects"
        keywords={["Kickbush Development", "UI/UX", "Projects"]}
      />
      <div className="row">
        {projectNodes.map(project => {
          const {
            _rawDescription: { text, heading, label },
            _rawMain,
            _rawLong,
          } = project
          return (
            <CardWrapper className="col-md-5 text-center" key={label}>
              <CardHeader>
                <CardHeading>{heading}</CardHeading>
              </CardHeader>
              <CardFieldset>
                <img
                  src={imageUrlFor(buildImageObj(_rawMain))
                    .width(600)
                    .height(Math.floor((9 / 16) * 600))
                    .url()}
                  alt={_rawMain.alt}
                  className="img-fluid"
                />
              </CardFieldset>
              <CardFieldset>
                <ExternalLink href={label}>{label}</ExternalLink>
              </CardFieldset>
              <CardFieldset>
                <BlockContent blocks={text} />
              </CardFieldset>
            </CardWrapper>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query projectsQuery {
    secondary: allFile(filter: { name: { eq: "kd_logo_white_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    projects: allSanityProject {
      edges {
        node {
          _rawDescription
          _rawLong
          _rawMain
        }
      }
    }
  }
`

export default Projects
